(function(global, init){
    (function(){
        //
        if(typeof $ !== "undefined"){
            init(global);
        }
    })();
}(typeof window !== "undefined" ? window : this, function(root){
	root.$gary={};
	$.ajaxSetup({
	    headers: {
	        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	    }
	});
	var Action = {
			call:function(actName,param,callback,errorcallback){
				var sMethod = actName.split('_')[0];
				var sControl = actName.split('_')[1];
				var sAction = actName.split('_')[2];
				$.ajax({
					type:sMethod,
					url:'/'+sControl+'/'+sAction,
					data:param,
					success:function(data){
						callback(data);
					},
                    error:function(data){                     
                        errorcallback(data);

                    }

				});
			}
	};
	root.$gary.callAction = Action; 
	  var EventDispatcher = {
        listeners : {},
        listen : function(type, listener){
            var self = this;
            var sTypeEncode = encodeURIComponent(type);
            if(self.listeners[sTypeEncode] === undefined ){
                self.listeners[sTypeEncode] = [];
            }
            if(self.listeners[sTypeEncode] && $.inArray(listener, self.listeners[sTypeEncode]) === - 1){
                self.listeners[sTypeEncode].push(listener);
            }
        },
        unlisten : function(type, listener){
            var self = this;
            var sTypeEncode = encodeURIComponent(type);
            var aListener = self.listeners[sTypeEncode];
            if(aListener !== undefined ){
                var nIndex = $.inArray(listener, aListener);
                if (nIndex !== - 1 ) {
                    listenerArray.splice(nIndex, 1);
                }
            }
        },
        fire : function(event){
            var self = this;
            if(!event.type){
                alert(event);
                return;
            }
            var sTypeEncode = encodeURIComponent(event.type);
            var aListener = self.listeners[sTypeEncode];
            if (aListener !== undefined){
                event.target = this;
            }
            var aListenerTmp = [];
            try{
                var nLen = aListener.length;
            }catch(e){
                debugger;
            }
            for (var i=0; i<nLen; i++){
                aListenerTmp[i] = aListener[i];
            }
            for (var i=0; i<nLen; i++){
                aListenerTmp[i].call(this, event);
            }
        }
    };
    //
    root.$gary.events = EventDispatcher;
    //
    var Event = {
        
    };
    //
    // scrolly
    jQuery.fn.n33_scrolly = function(offset) {

        jQuery(this).click(function(e) {
            var h = jQuery(this).attr('href'), target;

            if (h.indexOf('#') >=0 && h.length > 1 && (target = jQuery('#'+h.split('#')[1])).length > 0)
            {
                var pos = offset? Math.max(target.offset().top, 0)+offset:Math.max(target.offset().top, 0);
                e.preventDefault();
                jQuery('body,html').animate({ scrollTop: pos }, 'slow', 'swing');
            }
        });
    };
    root.$gary.event = Event;

    //input event

    if('onpropertychange' in document){
        var rInput = /^INPUT|TEXTAREA$/,
        isInput=function(ele){
            return rInput.text(ele.nodeName);
        }

        $.event.special.input={
            setup:function(){
                var $ele = this;
                if(!isInput($ele))return false;
                $.data($ele,'@oldValue',$ele.value);
                $.event.add($ele,'propertychange',function(event){
                    if($.data(this,'@oldValue')!==this.value){
                        $.event.trigger('input',null,this);
                    };
                    $.data(this,'@oldValue',this.value);
                });
            },
            teardown:function(){
                var $ele = this;
                if(!isInput($ele))return false;
                $.event.remove($ele,'propertychange');
                $.removeData($ele,'@oldValue');
            }
        }
    }

    $.fn.input=function(callback){
        return this.bind('input',callback);
    }

}));