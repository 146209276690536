var Component={};
var Action = $gary.callAction;
var Events = $gary.events;
Component.upload={
	inputEl:$('#fileupload'),
	filenameEl:$('#inputfilename'),
	collapseEl:$('#chooseType'),
	showAjaxErrorDetails: true,
	init:function(){
		var self =this;

		if(self.inputEl.size()>0){

			self.inputEl.fileinput({
				language: 'zh',
			    uploadUrl: "/doc/upload", // server upload action
			    uploadAsync: true,
			    showUpload: false, // hide upload button
			    minFileCount: 1,
			    maxFileCount: 5,
			    allowedFileExtensions:['doc','docx'],
			    previewClass:'embed-responsive',
			    previewSettings:{
								    image: {width: "auto", height: "20%"},
								    html: {width: "20%", height: "20%"},
								    text: {width: "15%", height: "20%"},
								    video: {width: "20%", height: "20%"},
								    audio: {width: "20%", height: "15%"},
								    flash: {width: "20%", height: "20%"},
								    object: {width: "15%", height: "20%"},
								    other: {width: "15%", height: "20%"}
								}
			}).on("fileuploaded",function(event,data,previewId,index){
				//self.collapseEl.collapse('show');
				if(!$('#orderform').hasClass('active')||self.filenameEl.val()==''){
					self.collapseEl.modal('show');
				}
				if ($('#uploadModal').length > 0){ //meaning upload in client/user page. Hide the uploadModal
					$('#uploadModal').modal('hide');
				}
				if(self.filenameEl.val()!=''){
					self.filenameEl.val(self.filenameEl.val()+','+data.response);
				}
				else{
					self.filenameEl.val(data.response);
				}
				
			}).on("filecustomerror",function(event,params){
				self.inputEl.fileinput('cancel');
			}).on("filepreupload",function(event, data, previewId, index, jqXHR){
				var uploadedfile = self.filenameEl.val().split(',');
				if(uploadedfile.length>=5){
					
					return {
						message:'No more than 5 files in ONE order',
						data:{}
					};
				}

			}).on("filebatchselected", function(event, files) {
				var uploadedfile = self.filenameEl.val().split(',');
				if(uploadedfile!=""&&files.length+uploadedfile.length>5){
					return {
						message:'No more than 5 files in ONE order',
						data:{}
					};
				}
				else{
					self.inputEl.fileinput("upload");
				}
			}).on("filecleared",function(event,key){
				self.filenameEl.val('');
			});
		}

	}

}

Component.events={
	register:{
		post_order_ready:{
			name:'post_order_ready',
			type:'click',
			param:function(){
				$('#'+this.name).prop('disabled',true);
				$('#'+this.name).parent().tooltip('destroy');
				return $('#'+this.name).parents('form').serialize();
			},
			callback:function(data){
				window.location.assign('http://'+window.location.hostname+'/'+data);
			},
			errorCallback:function(data){
				$('#'+this.name).prop('disabled',false);
				var $ul=$('<ul></ul>');
				if(typeof data.responseJSON !='undefined'){
					for(key in data.responseJSON){
						$ul.append(data.responseJSON[key]);
					}				
				}
				else{
					$ul.append(data.responseText);
				}
					var sToolstip=$ul[0].outerHTML;
				$('#'+this.name).parent().tooltip({html:true,title:sToolstip,placement:'top',
							trigger:'focus'}).tooltip('show');
			}
		},
		get_order_price:{
			name:'get_order_price',
			type:'input',
			timeout:300,
			param:function(){
				var $this =$('#'+this.name),
				paper_type = $('#paper_type_input').val(),
				sTooltip;
				if($('#orderform').find('[name="rewrite"]').size()>1){
					var rewrite = $('#orderform').find('[name="rewrite"]:checked').val();
				}
				else{
					var rewrite = $('#orderform').find('[name="rewrite"]').val();					
				}
				if($this.val()<=0){
					return false;
				}
				
				return 'paper_type='+paper_type+'&words='+$('#'+this.name).val()+'&rewrite='+rewrite;
			},
			callback:function(data){
				var $this =$('#'+this.name);
				var words = $this.val();
				var paper_type = $('#paper_type_input').val();
				var estimateHours = 8;
				var rewrite = 1;
				switch(paper_type){
					case '0':
						if(words > 1500 && words <= 3000){
							estimateHours = estimateHours+Math.ceil((words-1500)/500);
						}
						else if(words>3000){
							estimateHours = estimateHours+Math.ceil((words-2000)/300);
						}
					break;
					case '1':
						if(words>650 && words<=1500){
							estimateHours = estimateHours+Math.ceil((words-650)/500);
						}
						else if(words>1500){							
							estimateHours = estimateHours+Math.ceil((words-650)/400);
						}
						if($('#orderform').find('[name="rewrite"]').size()>1){
							rewrite = $('#orderform').find('[name="rewrite"]:checked').val();
						}
						else{
							rewrite = $('#orderform').find('[name="rewrite"]').val();					
						}
						switch(rewrite){
							case '8':
								estimateHours = Math.round(estimateHours*1.5);
							break;
							case '4':
								estimateHours = Math.round(estimateHours*1.2);
							break;
							default:
								estimateHours = estimateHours*1;
							break;
						}

					break;
					default:
					break;
				}
				if(data!=''){
					$('#callbackPrice').html(data+'  RMB');
					if(estimateHours==8){
						$('#callbackHours').html(estimateHours+'小时以内');
					}else{					
						$('#callbackHours').html('大约'+estimateHours+'小时');
					}
				
				}else{
					$('#callbackHours').html('');
				}
		

			},
			errorCallback:function(data){

			}
		},
		get_order_estimate:{
			name:'get_order_estimate',
			type:'input',
			timeout:300,
			param:function(){
				var $this =$('#'+this.name),
				paper_type = $('#paper_type_input').val(),
				sTooltip;
				if($('#orderform').find('[name="rewrite"]').size()>1){
					var rewrite = $('#orderform').find('[name="rewrite"]:checked').val();
				}
				else{
					var rewrite = $('#orderform').find('[name="rewrite"]').val();					
				}
				if($this.val()<=0){
					return false;
				}
				if($('#orderform').find('[name="no_hurry"]:checked').val()==1){
					var no_hurry = 1;
				}else{
					var no_hurry = 0;
				}
				return 'paper_type='+paper_type+'&words='+$('#'+this.name).val()+'&rewrite='+rewrite+'&no_hurry='+no_hurry;
			},
			callback:function(data){
				
				if(data!=''){
					$('#callbackPrice').html(data['price']+'  RMB');
					if(data['hours']==8){
						$('#callbackHours').html(data['hours']+'小时以内');
					}else{					
						$('#callbackHours').html('大约'+data['hours']+'小时');
					}

					if(typeof data['high_rate'] != 'undefined' && data['high_rate']>1){
						$('#callbackRate').html('x'+data['high_rate']).show();
						$('#orderform').find('#noHurryCheckbox').show();
					}else{
						$('#callbackRate').html('').hide();
					}
				
				}else{
					$('#callbackHours').html('');
				}
		

			},
			errorCallback:function(data){

			}
		},
		post_payment_charge:{
			name:'post_payment_charge',
			type:'click',
			channel:'',
			param:function(){
				var self = this;
				$('#'+this.name).prop('disabled',true);
				$('#'+this.name).tooltip('destroy');
				$form =  $('#'+this.name).parents('form');
				self.channel = $form.find('[name="channel"]:checked').val();
				return $form.serialize();
			},
			callback:function(data){
				console.log(data);
				var self = this;
				vmPayment.channels = Object.assign({},data);
				switch(self.channel){
					case 'alipay_pc_direct':
					vmPayment.channel = "支付宝";
					break;
					case 'wx_pub_qr':
					vmPayment.channel = "微信扫码";
					break;
					case 'paypal':
					vmPayment.channel = "Paypal";
					break;
					default:
					break;
				}
				$('#'+this.name).prop('disabled',false);
				$('#QRmodal').modal('show');
				
				// if(typeof data['is_view'] !='undefined' && data['is_view']){
				// 	$('#'+this.name).prop('disabled',false);
				// 	$('#QRmodal').find('.modal-content').prepend(data['view']);
				// 	$('#QRmodal').modal('show');
				// }
				// else{
				// 	switch(self.channel){
				// 	case 'alipay_pc_direct':
				// 	var debug = data['debug'];
				// 	if(typeof pingppPc != 'undefined'){
				// 		pingppPc.createPayment(data['charge'],function(result,err){
				// 			console.log(result);
				// 			console.log(err);
				// 		},debug);
				// 	}
				// 	break;
				// 	case 'paypal':
				// 		window.location.href=data;
				// 	break;
				// 	default:
				// 	$('#QRmodal').find('.modal-content').prepend(data);
				// 	break;
				// 	}
				// }
				
			},
			errorCallback:function(data){
				$('#'+this.name).prop('disabled',false);				
				$('#'+this.name).tooltip({
					placement:'top',
					title:data.responseText,
					trigger:'focus'
				}).tooltip('show');
			}
		},
	
	post_client_topup:{
			name:'post_client_topup',
			type:'click',
			channel:'',
			amount:'',
			param:function(){
				var self = this;
				$('#'+this.name).prop('disabled',true);
				$('#'+this.name).tooltip('destroy');
				if($('[name="amount"]:checked').val() == ''){
					return false;
				}
				self.amount = $('[name="amount"]:checked').val();
				$form =  $('#'+this.name).parents('form');
				self.channel = $form.find('[name="channel"]:checked').val();
				return $form.serialize();
			},
			callback:function(data){
				var self = this;
				vmPayment.amount = self.amount;
				vmPayment.channels = Object.assign({},data);
				switch(self.channel){
					case 'alipay_pc_direct':
					vmPayment.channel = "支付宝";
					break;
					case 'wx_pub_qr':
					vmPayment.channel = "微信扫码";
					break;
					case 'paypal':
					vmPayment.channel = "Paypal";
					break;
					default:
					break;
				}
				$('#'+this.name).prop('disabled',false);
				$('#QRmodal').modal('show');
			},
			errorCallback:function(data){
				$('#'+this.name).prop('disabled',false);				
				$('#'+this.name).tooltip({
					placement:'top',
					title:data.responseText,
					trigger:'focus'
				}).tooltip('show');
			}
		}
	},
	init:function(){
		var self = this;
		var arr_timeout = [];
		for(regEvent in self.register){
			var oCurrent = self.register[regEvent];
			$('#'+oCurrent.name).on(oCurrent.type,function(){
				var reg = self.register[$(this).attr('id')];
				if(typeof reg.timeout != 'undefined'){
					if(typeof arr_timeout[reg.name] != 'undefined'){
						clearTimeout(arr_timeout[reg.name]);
						arr_timeout.splice(reg.name,1);
					}
					arr_timeout[reg.name]=setTimeout(function(){
						var sParam = reg.param();

						Action.call(reg.name,sParam,function(data){
							reg.callback(data);
						},function(data){
							reg.errorCallback(data);
						});
					},reg.timeout);
				}
				else{
					var sParam = reg.param();

					Action.call(reg.name,sParam,function(data){
						reg.callback(data);
					},function(data){
						reg.errorCallback(data);
					});
				}
					
			});
		}
	}

};

var XiuXiu={
	ele:['.xiuxiu-fade','.xiuxiu-fly'],
	boom:function($this){
		var eleY = $this.scrollTop(),
		eleOffset = $this.offset(),
		eleHeight = $this.height(),
		windowY = $(window).scrollTop(),
		windowHeight = $(window).height();
		switch($this.data('xiuxiu')){
			case 'fade':
				if(eleOffset.top<=windowY+windowHeight && eleOffset.top>windowHeight*2/3+windowY){
					var opa = windowY/(eleOffset.top-(windowHeight*2/3));
					$this.css('opacity',opa);
					return true;
				}
				else if(eleOffset.top<=windowHeight*2/3+windowY){
					$this.css('opacity',1);
					return true;
				}
				else{
					return true;
				}
			break;
			case 'fly':
				if(eleOffset.top<=windowY+windowHeight && eleOffset.top>windowHeight*2/3+windowY){
					var direction = $this.hasClass('right')? 'right':'left';

				}
				else if(eleOffset.top<=windowHeight*2/3+windowY){
					$this.addClass('in');
					return true;
				}
				else{
					return true;
				}
			break;
			default:
				return false;
			break;

		}
	},
	xiuxiuScroll:function(){
		var self = this;
		$(window).scroll(function(){
			for(key in self.ele){
				$(self.ele[key]).each(function(){
					return self.boom($(this));
				});				
			}
		});
	},
	init:function(){
		var self = this;
		for(key in self.ele){
			$(self.ele[key]).each(function(){
				return self.boom($(this));
			});				
		}
	}

};

var Favorites = {
	mainEle:'#favoriteList',
	btnGroup:'.favoriteList-btn-group',
	btnBind:function(){
		var self = this;
		$(self.btnGroup).find('.moveUp').click(function(){
			$(self.btnGroup).children('button').prop('disabled',true);
			Action.call('post_favorites_moveup','id='+$(this).data('id')+'&_token='+$(self.mainEle).find('[name="_token"]').val(),function(){
				Events.fire({
					type:'favoritelist_refresh'
				});
			},
			function(){
				Events.fire({
					type:'favoritelist_refresh'
				});

			});
		});

		$(self.btnGroup).find('.moveDown').click(function(){
			$(self.btnGroup).children('button').prop('disabled',true);
			Action.call('post_favorites_movedown','id='+$(this).data('id')+'&_token='+$(self.mainEle).find('[name="_token"]').val(),function(){
				Events.fire({
					type:'favoritelist_refresh'
				});

			},
			function(){
				Events.fire({
					type:'favoritelist_refresh'
				});

			});

		});

		$(self.btnGroup).find('.remove').click(function(){
			$(self.btnGroup).children('button').prop('disabled',true);
			Action.call('post_favorites_remove','id='+$(this).data('id')+'&_token='+$(self.mainEle).find('[name="_token"]').val(),function(){
				Events.fire({
					type:'favoritelist_refresh'
				});

			},
			function(){
				Events.fire({
					type:'favoritelist_refresh'
				});

			});

		});
	},
	refreshList:function(){
		var self = this;
		Action.call('get_favorites_list','',function(data){
			$(self.mainEle).empty().append(data);
			self.btnBind();
		},function(){});
	},
	init:function(){
		var self = this;
		if($(self.mainEle).size() > 0){
			self.btnBind();
			Events.listen('favoritelist_refresh',function(event){
				self.refreshList(event);
			});		
		}

	}

};
Component.modules={
	scoring_mouseover:function(e){
		var $this = $(e.target);
		$this.tooltip('hide');
		$this.removeClass('empty').prevAll().removeClass('empty');
		var score = $this.find('input[type="radio"]').val();
		switch(score){
			case '1':
			var txt = '非常不满意,完全没达到预期,想申请退款';
			break;
			case '2':
			var txt = '不太满意, 跟预期的质量有差距, 希望能重改';
			break;
			case '3':
			var txt = '基本满意';
			break;
			case '4':
			var txt = '比较满意, 改得还算认真';
			break;
			case '5':
			var txt = '非常满意, 超出预期!';
			break;
			default:
		}
		$this.tooltip({
			title:txt,
			placement:'right',
			trigger:'hover',
		}).tooltip('show');
	},
	scoring_mouseout:function(e){
		var $this = $(e.target);
		if($this.parent().find('input[type="radio"]:checked').size()>0){
			$this.parent().find('input[type="radio"]:checked').parent().nextAll('label').addClass('empty');
		}else{
			$this.addClass('empty').prevAll().addClass('empty');
		}
	},
	scoring_click:function(e){
		var $this = $(e.target);
		$this.nextAll().addClass('empty');
		$this.removeClass('empty').prevAll().removeClass('empty');
	}

};
/* Service Vue Stuff */
new Vue({
	el: '#service',

	data:{
		basicInputValue:'',
		deepInputValue:''
		
	},

	computed:{
		basicPrice:{
			get: function(){
				if (this.basicInputValue > 0){
					var price = this.basicInputValue * 0.17;
					if (this.basicInputValue >= 5000)
						price = price * 1.4;
					else if (this.basicInputValue >= 3000)
						price = price * 1.2;

					return price.toFixed(2)
				}
				else
					return ''

			}
		},

		deepPrice:{
			get:function(){
				if (this.deepInputValue > 0){
					if (this.deepInputValue < 650)
						return 199
					else
						return (this.deepInputValue * (199/650) ).toFixed(2)
				}
				else
					return ''
			}
		}
	},
	
});

var OrderCheck = {
	currentStatus:'',
	intervalId:'',
	oid:'',
	checkStatus:function(){
		var self = this;
		Action.call('get_order_checkstatus','oid='+self.oid,function(data){
			if(self.currentStatus != data){
				window.location.reload();
			}
		},function(data){

		});
	},
	intervalStart:function(){
		var self = this;
		if(self.currentStatus == 1){
			if(self.intervalId ==''){
				self.invtervalId = window.setInterval(function(){
					self.checkStatus()
				},5000);			
			}
		}
		else{
			if(self.intervalId != ''){
				window.clearInterval(self.intervalId);
			}
		}
	},
	init:function(){
		var self = this;
		if($('input[name="order_status"]:hidden').size()>0){
			self.currentStatus = $('input[name="order_status"]:hidden').val();
			self.oid = $('input[name="oid"]:hidden').val();
			self.intervalStart();
		}
	}

};

$(document).ready(function(){

	Component.upload.init();
	Component.events.init();
	XiuXiu.init();
	XiuXiu.xiuxiuScroll();
	Favorites.init();
	OrderCheck.init();
	$('.scrolly').n33_scrolly(-50);


	//service part toggle show
	if ($(window).width() >= 769){
		$('.service').find('#deep-editing').mouseenter( function() {
			$(this).find('.button').css("opacity","1");
			$(this).stop().animate({'left':'17%'},500);
			$('#basic-editing').find('a').fadeOut(500);
		});
	}
	if ($(window).width() >= 769){
		$('.service').find('#deep-editing').mouseleave( function() {
			$(this).find('.button').css("opacity","0");
			$(this).stop().animate({'left':'82%'},500);
			$('#basic-editing').find('a').fadeIn(500);
		});
	}


	// Service part button shadow on hover
	$('.button').mouseenter( function() {
		$(this).addClass('shadow');
	});
	$('.button').mouseleave( function() {
		$(this).removeClass('shadow');
	});

	// mobile version on click change stufff
	$("#small-version").find('article').mouseenter( function() {
		$(this).children('.front').fadeOut(200);
		$(this).children('.back').fadeIn(200);
	});

	$("#small-version").find('article').mouseleave( function() {
		$(this).children('.back').fadeOut(200);
		$(this).children('.front').fadeIn(200);
	});

	$('[data-choose="single"]').each(function(){

		var $ele = $(this);
		$ele.find('.item').click(function(){
			var $click = $(this);
			var $active=$ele.find('.item.active');
			
			if($click.hasClass('active')) return;
			$click.addClass('active');
			$active.removeClass('active');
		});
		$ele.find('.item').mouseenter(function(){
			var $item = $(this);
			$item.children('img').css('opacity',0);
			$item.children('ul').css('opacity',1);
		}).mouseleave(function(){
			var $item = $(this);
			$item.children('img').css('opacity',1);
			$item.children('ul').css('opacity',0);
		});

	});
	$('#orderform').find('#paper_type_input').on('input',function(){

		$this = $(this);
		var $sign=$this.parents('#orderform').find('label[for="inputwords"] .glyphicon-question-sign'),
		sTooltipTemplate='<ul style="text-align:left"><li>若填写字数与实际字数不符，我们的editors将退回稿件，直到金额补齐</li><li>{paper_type}</li></ul>',
		$specified = $this.parents('#orderform').find('label[for="specified"]').parent();
		$this.parents('#orderform').find('label[for="papertype"] .glyphicon-question-sign').tooltip();
		switch($this.val()){
			case "0":
				sTooltipTemplate=sTooltipTemplate.replace('{paper_type}','若不需要修改Bibliography，请删除后再提交');
				$specified.find('option').first().prop('selected',true);
				$specified.css('display','none');
			break;
			case "1":
				sTooltipTemplate=sTooltipTemplate.replace('{paper_type}','199元起售，超过650词后每词约0.3元');
				$specified.css('display','block');
			break;
			default:
			break;
		}
		$sign.data('bs.tooltip','');
		$sign.tooltip({
			html:true,
			title:sTooltipTemplate,
			trigger:'hover',
			placement:'top'
		});
		if($this.val()==1){
			$('.rewrite-div').show();
		}else{
			$('.rewrite-div').hide();
		}
		$('#get_order_estimate').trigger('input');

	});
	if($('#orderform').find('#paper_type_input').val()==1){
		$('.rewrite-div').show();
	}else{
		$('.rewrite-div').hide();
	}
	
	$('#orderform').find('#get_order_estimate').trigger('input');
	$('#orderform').find('[name="rewrite"]').change(function(){
		$('#get_order_estimate').trigger('input');
	});
	$('#wait-form').find('#chooseFavorites').click(function(){
		if($(this).prop('checked')){
			$('#wait-form').find('#canWait').prop('disabled',false);
			$('#wait_for-hidden-input').val(0);
		}
		else{
			$('#wait-form').find('#canWait').prop('checked',false).prop('disabled',true);
			$('#wait-form').find('[name="wait_for"]').val(undefined);
			$('#wait_for-hidden-input').val(undefined);
		}
	});
	$('#wait-form').find('#canWait').click(function(){
		if($(this).prop('checked')){
			$('#wait-form').find('[name="wait_for"]').prop('disabled',false).val(1);
			$('#wait_for-hidden-input').val($('#wait-form').find('[name="wait_for"]').val());
		}
		else{
			$('#wait-form').find('[name="wait_for"]').prop('disabled',true).val(undefined);
			$('#wait_for-hidden-input').val(0);
		}
	});
	$('#wait-form').find('[name="wait_for"]').on('input',function(){
		var wait_for = $(this).val();
		if(wait_for>0 && wait_for <=72){
			$('#wait_for-hidden-input').val(wait_for);
		}
		else if(wait_for > 72){
			$(this).val(72);
			$('#wait_for-hidden-input').val(72);
		}
		else{
			$('#wait_for-hidden-input').val(0);
		}
	});
	$('.switchheader').each(function(){

		var $ele=$(this);
		var switchTo=function(next,params){
			var $next = $(next);
			if($next.hasClass('active')) return;
			if(params){
				for(key in params){
					$('#orderform').find('[name="'+key+'"]').children('[value="'+params[key]+'"]').attr('selected',true).trigger('input');
				}
			}
			var $active = $ele.find('.item.active');
			/*if($.support.transition){
				$next.addClass('switching right');
				$active.addClass('switching right');
				$active
			        .one('bsTransitionEnd', function () {
			        	$next.removeClass('switching right').addClass('active');
						$active.removeClass('switching right active');
			        })
			        .emulateTransitionEnd(600);
				
			}*/
			
		}

		$(document).find('[data-switch-to]').click(function(){
			var $this = $(this);
			var paper_type=$this.parents('#chooseType').find('.active[data-choose-value]').data('choose-value');
			// Assigning the paper_type value to form input.
			$('#paper_type_input').val(paper_type);

			var param = new Array();
			param['paper_type']= paper_type;
			$this.parents('.modal').modal('hide');
			switchTo($this.data('switch-to'),param);
		});
		$ele.find('button').click(function(){
			$(this).parents('#orderform').tooltip('hide');
		});
		$ele.find('.dismiss').click(function(){
			switchTo('#logo');
			var $fileinput=Component.upload.inputEl;
			$fileinput.fileinput('clear');
		});
	});
	
	//New Phases Modal Stuff	

	$('[data-next]').click(function(){
		var $this = $(this);
		var paper_type=$this.parents('#chooseType').find('.active[data-choose-value]').data('choose-value');
		// Assigning the paper_type value to form input.
		$('#paper_type_input').val(paper_type);
		//Phase 2 show which btn depending on paper_type.
		if (paper_type == 0){//普通润色
			var $btn = $('#post_order_ready').parent().detach();
			$('#phase2modal').find('.btn-row').prepend($btn);

			$('#phase2NextBtn').parent().hide(); // Hide the parent, namely the entire col
		}else{
			var $btn = $('#post_order_ready').parent().detach();
			$('#phase3modal').find('.btn-row').prepend($btn);
			$('#phase2NextBtn').parent().show();

		}
		//close tooltip
		$this.parent().siblings().each(function(){
			$(this).tooltip('destroy');

		});

		//transition
		if($.support.transition){
			$next =$($this.data('next'));
			$this.parents('.modal-dialog').addClass('fading up').fadeOut(300);
			$next.addClass('fading up');
			$this.parents('.modal-dialog')
		        .one('bsTransitionEnd', function () {
		        	$next.fadeIn(300).removeClass('fading up');
					$this.parents('.modal-dialog').removeClass('active').removeClass('fading up');
					$next.addClass('active');
					$('#get_order_estimate').trigger('input');
		        })
		        .emulateTransitionEnd(600);
			
		}

		
	});
	$('#chooseType').find('[data-dismiss="modal"]').click(function(){
			var $fileinput=Component.upload.inputEl;
			$fileinput.fileinput('clear');
			$('[data-next="#phase1modal"]').trigger('click');
		});

	$('[data-toggle="tooltip"]').tooltip();

	// Phase 3 modal radio button animations. Change color when selected and show descriptions.

	$('.rewrite-wrapper').click(function(){
		$('#phase3modal').find('.selected').removeClass('selected');
		$(this).addClass('selected',500);
		$(this).find('input').prop('checked', true);
	});
	
	$('#no_hurry').click(function(){
		$('#get_order_estimate').trigger('input');
	});
	$('button[type="submit"]').click(function(e){
		if($(this).data('submit-flag')==true){
			e.preventDefault();
		}
		else{
			$(this).data('submit-flag',true);
		}

	});
});